import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    BtnBold, BtnBulletList, BtnItalic, BtnLink, BtnNumberedList, BtnRedo, BtnStrikeThrough,
    BtnUnderline, BtnUndo, Editor, EditorProvider, Toolbar
} from 'react-simple-wysiwyg';
import { AddNewEssayData } from '../../../api_calls/request';
import { getEssay, getConversationHistory, querySchools, sendChatMessage, updateEssay } from '../../../api_calls';
import { storeContext } from '../../../store';
import { getWordCount } from '..';
import WordCount from '../NewEssay/WordCount';
import { language } from '../../../Language';
import { useParams } from 'react-router-dom';
import { QuerySchoolsResponse } from '../../../api_calls/response';
import { DeepChat } from 'deep-chat-react';
import { Role } from '../../../utils';

interface Props {
    className?: string;
    initialLoading: boolean;
}

interface MessageContent {
    role?: Role;
    text?: string;
}

interface SendChatStream {
    done: boolean;
    message: string;
}

const strings = language().signedIn.essays.existingEssays.existingEssay;

const NewEssay = (props: Props) => {
    const { className } = props;
    const { setAlert, account: { firstName } } = useContext(storeContext);
    const { essayId } = useParams();

    const [wordCount, setWordCount] = useState(0);
    const [essayInfo, setEssayInfo] = useState<AddNewEssayData>({ title: '', schoolId: '', data: '' });
    const [disabled, setDisabled] = useState(true);
    const [messageHistory, setMessageHistory] = useState<MessageContent[]>([]);
    const [appliedSchools, setAppliedSchools] = useState<QuerySchoolsResponse[]>([]);
    const conversationId = useRef<string>('');


    useEffect(() => {
        if (essayId) {
            getEssay(essayId)
                .then((essay) => {
                    setEssayInfo({ title: essay.title, schoolId: essay.schoolId, data: essay.essay });
                    setWordCount(getWordCount(essay.essay));
                })
                .catch(() => setAlert({ severity: 'error', message: strings.updateError }))
                .finally(() => setDisabled(true));

            querySchools({ applied: true })
                .then((schools) => setAppliedSchools(schools))
                .catch((e) => setAlert({ severity: 'error', message: e }));

            getConversationHistory(essayId)
                .then((conversation) => {
                    conversationId.current = conversation.conversationId;
                    setMessageHistory(conversation.messages.map((message) => ({ role: message.role, text: message.content })));
                })
                .catch((e) => setAlert({ severity: 'error', message: e }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateEssay = () => {
        setDisabled(true);
        updateEssay(essayId as string, essayInfo)
            .then(() => setAlert({ severity: 'success', message: strings.updateSuccess }))
            .catch(() => setAlert({ severity: 'error', message: strings.updateError }));
    }

    const handleEssayTypeChange = (e: any) => setEssayInfo((_essayInfo) => {
        setDisabled(false);
        let data = e.target.value;

        if (data === '<br>') {
            data = '';
        }

        setWordCount(getWordCount(data));

        return { ..._essayInfo, data };
    });

    // Memoize the DeepChat component
    const MemoizedDeepChat = useMemo(() => {
        const customDeepChatConnect = ({
            stream: true,
            handler: (body: any, signals: any) => sendChatMessage({ conversationId: conversationId.current, message: body.messages[0].text })
                .then(async (response) => {
                    if (response.status === 200) {
                        signals.onOpen();

                        const reader = response.body?.getReader();
                        const decoder = new TextDecoder("utf-8");

                        while (true) {
                            const value = (await reader?.read())?.value;
                            let decodedValue = decoder.decode(value, { stream: true });
                            const stream: SendChatStream = JSON.parse(decodedValue);
                            signals.onResponse({ text: stream.message });

                            if (stream.done) {
                                break;
                            }
                        }
                    } else {
                        signals.onResponse({ error: strings.aiResponseError });
                    }
                })
                .catch((e) => {
                    setAlert({ severity: 'error', message: e.toString() });
                })
                .finally(() => signals.onClose())
        });

        return (
            <DeepChat
                chatStyle={{ width: '100%', height: '600px', borderRadius: '5px', backgroundColor: 'white' }}
                connect={customDeepChatConnect}
                history={messageHistory}
                textInput={{
                    placeholder: { text: strings.textInputPlaceHolder },
                    styles: {
                        container: {
                            width: '100%',
                            margin: '30',
                            border: 'unset',
                            borderTop: '1px solid #d5d5d5',
                            borderRadius: '0px',
                            boxShadow: 'unset'
                        }
                    }
                }}
                auxiliaryStyle="
                    ::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                    }
                    ::-webkit-scrollbar-thumb {
                    background-color: #54a7ff;
                    border-radius: 5px;
                }"
                introMessage={{ text: strings.introMessage.replace('{schoolName}', appliedSchools.filter(({ id }) => id === essayInfo.schoolId)?.[0]?.name) }}
                names={{ default: { text: strings.aiName, position: 'right' }, user: { text: firstName, position: 'left' } }}
                avatars={true}
                displayLoadingBubble={true}
                introPanelStyle={{ backgroundColor: '#fffeec' }}
                messageStyles={{ loading: { message: { styles: { bubble: { backgroundColor: '#3793ff', fontSize: '20px', color: 'white' } } } } }}
            />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageHistory, appliedSchools, firstName, essayInfo.schoolId]);


    return (
        <div className={className}>
            <Grid container justifyContent='center' spacing={1} height='100%'>
                <Grid item xs={12} sm={12} md={8} style={{ minHeight: '50%' }} justifyContent='center' alignContent='center'>
                    <Grid container marginBottom='10px' alignItems='center' justifyContent='center' spacing={3}>
                        <Grid item xs={6} sm={5} alignItems='center'>
                            <TextField
                                value={essayInfo.title}
                                onChange={(e) => {
                                    setDisabled(false);
                                    setEssayInfo((_essayInfo) => ({ ..._essayInfo, title: e.target.value }));
                                }}
                                size='small'
                                fullWidth
                                placeholder={strings.title}
                                label={strings.title}
                                variant='standard' />
                        </Grid>
                        <Grid item xs={6} sm={5} display='flex'>
                            <FormControl fullWidth>
                                <InputLabel>{strings.schoolName}</InputLabel>
                                <Select
                                    value={essayInfo.schoolId}
                                    onChange={(e) => {
                                        setDisabled(false);
                                        setEssayInfo((_essayInfo) => ({ ..._essayInfo, schoolId: e.target.value as string }));
                                    }}
                                    variant='standard'
                                >
                                    {appliedSchools.map((school, idx) => <MenuItem key={idx} value={school.id}>{school.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} display='flex' justifyContent='flex-end'>
                            <Button
                                variant='contained'
                                onClick={handleUpdateEssay}
                                disabled={disabled}
                            >
                                {strings.update}
                            </Button>
                        </Grid>
                    </Grid>
                    <EditorProvider>
                        <Editor
                            placeholder={strings.placeHolder}
                            value={essayInfo.data}
                            onChange={handleEssayTypeChange}
                            containerProps={{ style: { height: '93%', background: 'white' } }}
                        >
                            <Toolbar>
                                <BtnUndo />
                                <BtnRedo />
                                <BtnBold />
                                <BtnItalic />
                                <BtnUnderline />
                                <BtnBulletList />
                                <BtnNumberedList />
                                <BtnStrikeThrough />
                                <BtnLink />
                                <WordCount wordCount={wordCount} strings={strings} />
                            </Toolbar>
                        </Editor>
                    </EditorProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    {MemoizedDeepChat}
                </Grid>
            </Grid>
        </div>
    );
};

export default styled(NewEssay)`
    border-radius: 5px;
    padding: 10px;
    height: calc(100vh - 87px);

    .rsw-toolbar {
        display: flex;
        justify-content: center;
        size: 30px;
    }

    .rsw-toolbar .rsw-btn {
        font-size: 16px;
        padding: 10px;
        width: 40px; 
        height: 40px;
    }
`;
