import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { QuerySchoolsResponse } from '../../api_calls/response';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { storeContext } from '../../store';
import { querySchools } from '../../api_calls';

interface Props {
    className?: string;
    strings: any;
}

const Deadlines = (props: Props) => {
    const { className, strings } = props;

    const { setAlert } = useContext(storeContext);

    const [appliedSchools, setAppliedSchools] = useState<QuerySchoolsResponse[]>([]);
    const [fetchingSchools, setFetchingSchools] = useState(false);

    useEffect(() => {
        setFetchingSchools(true);

        querySchools({ applied: true })
            .then((schools: QuerySchoolsResponse[]) => {
                const sortedSchools = schools.sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime());
                setAppliedSchools(sortedSchools);
            })
            .catch((e) => setAlert({ severity: 'error', message: e.message }))
            .finally(() => setFetchingSchools(false));
    }, [setAlert]);

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h4'>{strings.title}</Typography>
                </Grid>
                <Grid container item xs={12}>
                    {fetchingSchools ? <CircularProgress size={20} /> :
                        appliedSchools.length ?
                            appliedSchools.map((school) => <Deadline key={school.id} {...school} />) :
                            <Instructions instructions={strings.instructions} />
                    }
                </Grid>
            </Grid>
        </div >
    );
};

const Deadline = ({ name, deadline }: QuerySchoolsResponse) => (
    <Grid item xs={12} style={{ borderBottom: '1px solid black', padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6'>{name}</Typography>
        <Typography variant='body1'>{new Date(deadline).display()}</Typography>
    </Grid>
);

interface InstructionsProps {
    instructions: string;
}

export const Instructions = ({ instructions }: InstructionsProps) => (
    <Typography variant='body1'>
        {instructions}
    </Typography>
);

export default styled(Deadlines)`

`;
