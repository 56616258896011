import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ApplicationDialog } from './CollegeList';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { addSchool } from '../../api_calls';
import { storeContext } from '../../store';

interface Props {
    applicationDialog: ApplicationDialog;
    setApplicationDialog: Dispatch<SetStateAction<ApplicationDialog>>;
    defaultApplicationDialog: ApplicationDialog;
    strings: any;
    handleAddToColleges: (schoolId: string) => void;
    className?: string;
}

const CollegeApplicationDialog = (props: Props) => {
    const { applicationDialog, setApplicationDialog, defaultApplicationDialog, handleAddToColleges, strings, className } = props;
    const { setAlert } = useContext(storeContext);

    const [disableButton, setDisableButton] = useState(false);

    const disableAdd = (
        !applicationDialog.major || !applicationDialog.schoolId || !applicationDialog.schoolName || !applicationDialog.year || disableButton
    );

    const handleAdd = () => {
        setDisableButton(true);
        addSchool({
            schoolId: applicationDialog.schoolId,
            major: applicationDialog.major,
            year: applicationDialog.year,
        })
            .then(() => {
                setAlert({ severity: 'success', message: strings.success });
                handleAddToColleges(applicationDialog.schoolId);
                setTimeout(() => {
                    setApplicationDialog(defaultApplicationDialog);
                    setAlert({ severity: 'success', message: '' });
                    setDisableButton(false);
                }, 1000);
            })
            .catch((e) => {
                setAlert({ severity: 'error', message: e });
                setDisableButton(false);
            });
    };

    return (
        !!applicationDialog.schoolId ? (
            <Dialog
                open={!!applicationDialog.schoolId}
                onClose={() => setApplicationDialog(defaultApplicationDialog)}
                className={className}
            >
                <DialogTitle justifyItems='center' margin='5px'>
                    <Typography variant='h4'>
                        {strings.title.replace('{school_name}', applicationDialog.schoolName)}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyItems='center' marginTop='1px'>
                        <Grid item xs={12}>
                            <TextField
                                size='small'
                                fullWidth
                                placeholder={strings.major}
                                label={strings.major}
                                value={applicationDialog.major}
                                onChange={(e) => setApplicationDialog((appDialog) => ({ ...appDialog, major: e.target.value }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size='small'
                                fullWidth
                                placeholder={strings.year}
                                label={strings.year}
                                type='number'
                                value={applicationDialog.year || ''}
                                onChange={(e) => setApplicationDialog((appDialog) => ({ ...appDialog, year: +e.target.value }))}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='text'
                        size='small'
                        onClick={() => setApplicationDialog(defaultApplicationDialog)}
                    >
                        {strings.close}
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={handleAdd}
                        disabled={disableAdd}
                    >
                        {strings.add}
                    </Button>
                </DialogActions>
            </Dialog >) : null
    );
};

export default styled(CollegeApplicationDialog)`

`;
