import { styled } from '@mui/material/styles';
import { QuerySchoolsResponse } from '../../api_calls/response';
import { Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { removeSchool } from '../../api_calls';
import { useContext } from 'react';
import { storeContext } from '../../store';

interface Props {
    className?: string;
    college: QuerySchoolsResponse;
    openApplicationDialog: (schoolId: string, schoolName: string) => void;
    handleRemoveFromColleges: (schoolId: string) => void;
    strings: any;
}

const CollegeCard = (props: Props) => {
    const { className, college, openApplicationDialog, handleRemoveFromColleges, strings } = props;
    const { setAlert } = useContext(storeContext);

    const handleAddSchool = () => openApplicationDialog(college.id, college.name);
    const handleRemoveSchool = () => removeSchool({ schoolId: college.id }).then(() => {
        handleRemoveFromColleges(college.id);
        setAlert({ severity: 'success', message: strings.removeSuccess });
    });

    return (
        <Grid container className={className} alignItems='center'>
            <Grid item xs={3}>
                <Typography variant='h6'>{college.name}</Typography>
            </Grid>
            <Grid item xs={3} justifyItems='center'>
                <Typography variant='h6'>{strings[college.schoolType]}</Typography>
            </Grid>
            <Grid item xs={1} justifyItems='center'>
                <Typography variant='body1'>{college.state}</Typography>
            </Grid>
            <Grid item xs={3} justifyItems='center'>
                <Typography variant='body1'>{(100 * college.admissionRate || 0).toFixed(2)}%</Typography>
            </Grid>
            <Grid item xs={2} justifyItems='center'>
                <div>
                    <IconButton size='small' onClick={() => college.added ? handleRemoveSchool() : handleAddSchool()}>
                        {college.added ? <RemoveIcon className='remove-button' /> : <AddIcon className='add-button' />}
                    </IconButton>
                </div>
            </Grid>
        </Grid>
    );
};

export default styled(CollegeCard)`
    background-color: rgb(226, 226, 226);
    padding: 10px;
    border-radius: 15px;
    display: flex;

    &:hover {
        transform: scale(1.01);
        transition: transform 0.2s;
    }

    .remove-button {
        border-radius: 5px;
        background-color: #ff9595;
    }

    .add-button {
        border-radius: 5px;
        background-color: #94ca94;
    }
`;
