import _axios, { AxiosRequestConfig } from 'axios';
import { apiBaseUrl } from '../environment';
import { GetEssayResponse, EssayQueryResponse, FetchAccount, JoinWaitlistResponse, QuerySchoolsResponse, SignInResponse, SignUpResponse, GetConversationHistoryResponse } from './response';
import qs from 'qs';
import { AddSchool, AddNewEssayData, RemoveSchool, SchoolFilterParams, SignInFormData, SignUpFormData, UpdateAccountData, WaitlistFormData, QueryEssayParams, SendChatData } from './request';

const POST = 'POST';
const GET = 'GET';
const PUT = 'PUT';
const DELETE = 'DELETE';
const STORE_KEY = 'aspired_local_store';

const debounce = (func: any, delay: number = 500): (queryParams: any) => Promise<any> => {
    let timeoutId: any;

    return (queryParams?: any) => {
        clearTimeout(timeoutId);

        return new Promise((resolve, reject) => {
            timeoutId = setTimeout(async () => {
                try {
                    resolve(await func(queryParams));
                } catch (error) {
                    reject(error);
                }
            }, delay);
        });
    };
};

export const getLocalJwt = () => localStorage.getItem(STORE_KEY);
export const setLocalJwt = (jwt: string) => localStorage.setItem(STORE_KEY, jwt);
export const clearLocalJwt = () => localStorage.removeItem(STORE_KEY);

const axiosInstance = _axios.create({ baseURL: apiBaseUrl, paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'comma' }) } });

const axios = async (axiosRequest: AxiosRequestConfig, authorized: boolean = true) => {
    try {
        if (authorized) {
            axiosRequest.headers = { ...axiosRequest.headers, Authorization: `Bearer ${getLocalJwt()}` };
        }

        if (axiosRequest.data) {
            axiosRequest.data = convertCamelToSnake(axiosRequest.data);
        }

        if (axiosRequest.params) {
            axiosRequest.params = convertCamelToSnake(axiosRequest.params);
        }

        return convertSnakeToCamel((await axiosInstance({ method: GET, ...axiosRequest })).data);
    } catch (err: any) {
        throw new Error(err.response.data.message || err.response.data);
    }
};

export const joinWaitlist = (data: WaitlistFormData): Promise<JoinWaitlistResponse> => axios({ url: '/public/waitlist', method: POST, data }, false);
export const signUp = (data: SignUpFormData): Promise<SignUpResponse> => axios({ url: '/public/sign-up', method: POST, data }, false);
export const signIn = (data: SignInFormData): Promise<SignInResponse> => axios({ url: '/public/sign-in', method: POST, data }, false);
export const fetchAccount = (): Promise<FetchAccount> => axios({ url: '/student' });
export const updateAccount = (data: UpdateAccountData): Promise<void> => axios({ url: '/student', method: POST, data });
export const querySchools = debounce((params: SchoolFilterParams): Promise<QuerySchoolsResponse[]> => axios({ url: '/school', method: GET, params }));
export const addSchool = (data: AddSchool): Promise<void> => axios({ url: '/school', method: POST, data });
export const removeSchool = (data: RemoveSchool): Promise<void> => axios({ url: '/school', method: DELETE, data });
export const addEssay = (data: AddNewEssayData): Promise<GetEssayResponse> => axios({ url: '/essay', method: POST, data });
export const queryEssays = (params: QueryEssayParams): Promise<EssayQueryResponse[]> => axios({ url: '/essay', params });
export const getEssay = (id: string): Promise<GetEssayResponse> => axios({ url: `/essay/${id}` });
export const updateEssay = (id: string, data: AddNewEssayData): Promise<GetEssayResponse> => axios({ url: `/essay/${id}`, method: PUT, data });
export const deleteEssay = (id: string): Promise<void> => axios({ url: `/essay/${id}`, method: DELETE });
export const getConversationHistory = (essayId: string): Promise<GetConversationHistoryResponse> => axios({ url: '/chat', params: { essayId } });
export const sendChatMessage = (data: SendChatData): Promise<Response> =>
    fetch(`${apiBaseUrl}/chat`, {
        method: POST,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getLocalJwt()}`, // Add your Authorization header
        },
        body: JSON.stringify(convertCamelToSnake(data)),
    });

const convertSnakeToCamel = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(convertSnakeToCamel);
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((acc, key) => {
            const camelKey = key.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
            return { ...acc, [camelKey]: convertSnakeToCamel(obj[key]) };
        }, {});
    } else {
        return obj;
    }
}

const convertCamelToSnake = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(convertCamelToSnake);
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((acc, key) => {
            const snakeKey = key.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);
            return { ...acc, [snakeKey]: convertCamelToSnake(obj[key]) };
        }, {});
    } else {
        return obj;
    }
}
