import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Greetings from './Greetings';
import ProfileProgress from './ProfileProgress';
import Deadlines from './Deadlines';
import Activities from './Activities';
import EssayEditor from './EssayEditor';
import Planner from './Planner';
import { language } from '../../Language';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const strings = language().signedIn.dashboard;

const Dashboard = (props: Props) => {
    const { className, initialLoading } = props;

    return (
        <div className={className} >
            <Grid container>
                {initialLoading ? <CircularProgress /> : (
                    <>
                        <Grid item className='dashboard-component' xs={12} sm={12} md={4}>
                            <Greetings strings={strings.greetings} />
                        </Grid>
                        <Grid item className='dashboard-component' xs={12} sm={12} md={8}>
                            <ProfileProgress strings={strings.profileProgress} />
                        </Grid>

                        <Grid item className='dashboard-component' xs={12} sm={6} md={5}>
                            <Deadlines strings={strings.deadlines} />
                        </Grid>
                        <Grid item className='dashboard-component' xs={12} sm={6} md={7}>
                            <EssayEditor strings={strings.essayEditor} />
                        </Grid>

                        <Grid item className='dashboard-component' xs={12} sm={12} md={6}>
                            <Activities strings={strings.activities} />
                        </Grid>
                        <Grid item className='dashboard-component' xs={12} sm={12} md={6}>
                            <Planner strings={strings.planner} />
                        </Grid>
                    </>

                )}
            </Grid>
        </div>
    );
};

export default styled(Dashboard)`
    padding: 5px;

    .dashboard-component {
        padding: 5px;

        & > * {
            background-color: white;
            padding: 10px;
            border-radius: 5px;
            height: 100%;
        }
    }
`;
