import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
    className?: string;
    wordCount: number;
    strings: any;
}

const WordCount = (props: Props) => {
    const { className, wordCount, strings } = props;

    return (
        <div className={className}>
            <div>
                <Typography variant='h5'>
                    {strings.wordCount}:
                </Typography>
            </div>
            <div>
                <Typography variant='body1'>
                    {wordCount}
                </Typography>
            </div>
        </div>
    );
};

export default styled(WordCount)`
    display: flex;
    width: 110px;
    justify-content: space-around;
    align-items: center;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


`;
