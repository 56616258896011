import { styled } from '@mui/material/styles';
import { EssayQueryResponse } from '../../../api_calls/response';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
    className?: string;
    essayInfo: EssayQueryResponse;
}

const ExistingEssayCard = (props: Props) => {
    const { className, essayInfo: { title, id } } = props;

    const navigate = useNavigate();

    return (
        <div className={className} onClick={() => navigate(`/essays/${id}`)}>
            <Typography variant='h5'>{title}</Typography>
        </div>
    );
};

export default styled(ExistingEssayCard)`
    &:hover {
        cursor: pointer;
    }

    padding: 10px;
    border-radius: 5px;
    background-color: white;
`;
