
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './Pages/Entry/SignIn';
import SignUp from './Pages/Entry/SignUp';
import { useContext } from 'react';
import { storeContext } from './store';
import TopMenu from './Menus/TopMenu';
import SideMenu from './Menus/SideMenu';
import ForgotPassword from './Pages/Entry/ForgotPassword';
import { useMediaQuery, Theme } from '@mui/material';
import UpdatePassword from './Pages/Entry/UpdatePassword';
import Waitlist from './Pages/Entry/Waitlist';
import AcademicPlanner from './Pages/AcademicPlanner';
import Essays from './Pages/Essays';
import Extracurriculars from './Pages/Extracurriculars';
import Colleges from './Pages/Colleges';
import Dashboard from './Pages/Dashboard';
import Profile from './Pages/Profile';
import ExistingEssay from './Pages/Essays/ExistingEssays/ExistingEssay';
import { isProduction } from './environment';
import JirayaAlerts from './Alert';
import './utils';

const App = () => {
  const { isLoggedIn, sideMenu, toggleSideMenu, localLogIn, initialLoading, alert, setAlert } = useContext(storeContext);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <div className='App'>
      <Router>
        <TopMenu isSmallScreen={isSmallScreen} toggleSideMenu={toggleSideMenu} isLoggedIn={isLoggedIn} />
        <SideMenu isOpen={sideMenu} toggleSideMenu={toggleSideMenu} />
        <div style={{ margin: 10, height: 'calc(100vh - 78px)' }}>
          <Routes>
            {
              !isLoggedIn ? (
                <>
                  <Route path='/' element={!isProduction ? <Navigate to='/sign-in' /> : <Waitlist isSmallScreen={isSmallScreen} />} />
                  <Route path='/sign-in' element={<SignIn localLogIn={localLogIn} isSmallScreen={isSmallScreen} />} />
                  <Route path='/sign-up' element={<SignUp localLogIn={localLogIn} isSmallScreen={isSmallScreen} />} />
                  <Route path='/forgot-password' element={<ForgotPassword isSmallScreen={isSmallScreen} />} />
                  <Route path='/update-password' element={<UpdatePassword isSmallScreen={isSmallScreen} />} />
                  <Route path='*' element={<Navigate to='/' />} />
                </>
              ) : (
                <>
                  <Route path='/' element={<Dashboard initialLoading={initialLoading} />} />
                  <Route path='/planner' element={<AcademicPlanner initialLoading={initialLoading} />} />
                  <Route path='/essays' element={<Essays initialLoading={initialLoading} />} />
                  <Route path='/essays/:essayId' element={<ExistingEssay initialLoading={initialLoading} />} />
                  <Route path='/extra-curriculars' element={<Extracurriculars initialLoading={initialLoading} />} />
                  <Route path='/colleges' element={<Colleges initialLoading={initialLoading} />} />
                  <Route path='/profile' element={<Profile initialLoading={initialLoading} />} />
                  <Route path='*' element={<Navigate to='/' />} />
                </>
              )
            }
          </Routes>
        </div>
      </Router>
      <JirayaAlerts alert={alert} setAlert={setAlert} />
    </div>
  );
}

export default App;
