import { useState, ChangeEvent, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Grid, TextField, Typography, Box } from '@mui/material';
import { language } from '../../Language';
import { joinWaitlist } from '../../api_calls';
import { WaitlistFormData } from '../../api_calls/request';
import { storeContext } from '../../store';

interface Props {
    className?: string;
    isSmallScreen: boolean;
    strings?: any;
}

const inputs = [
    ['firstName', 'lastName'],
    ['email'],
    ['highSchool'],
    ['city', 'state'],
    ['graduationYear'],
];

const strings = language().waitList;

const WaitlistPage = (props: Props) => {
    const { className, isSmallScreen } = props;

    const { setAlert } = useContext(storeContext);

    const [formData, setFormData] = useState<WaitlistFormData>({
        firstName: '',
        lastName: '',
        email: '',
        highSchool: '',
        city: '',
        state: '',
        graduationYear: '',
    });

    // const [submissionStatus, setSubmissionStatus] = useState<'success' | 'error' | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData((_formData) => ({ ..._formData, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await joinWaitlist(formData);
            setAlert({ severity: 'success', message: strings.joinWaitlistSuccess });
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                highSchool: '',
                city: '',
                state: '',
                graduationYear: '',
            });
        } catch (error) {
            setAlert({ severity: 'error', message: strings.joinWaitlistError });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid className={className} item xs={12} md={8}>
            <div id='waitlist-content'>
                <div id='title'>
                    <Typography variant='h2' align='center' gutterBottom>
                        {strings.title}
                    </Typography>
                </div>
                <div id='subtitle'>
                    <Typography variant='body2' color='textSecondary' align='center' gutterBottom>
                        {strings.subTitle}
                    </Typography>
                </div>

                {inputs.map((inputRow, index) => (
                    <Box
                        display='flex'
                        flexDirection={isSmallScreen ? 'column' : 'row'}
                        gap={2}
                        marginBottom={2}
                        key={index}
                    >
                        {inputRow.map((input) => (
                            <TextField
                                key={input}
                                label={(strings as any)[input]}
                                variant='outlined'
                                fullWidth
                                name={input}
                                value={(formData as any)[input]}
                                onChange={handleChange}
                                disabled={loading}
                            />
                        ))}
                    </Box>
                ))}

                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={handleSubmit}
                    sx={{ marginTop: 2 }}
                    disabled={loading}
                >
                    {loading ? strings.submitting : strings.submit}
                </Button>
            </div>
        </Grid>
    );
};

export default styled(WaitlistPage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px;

  #waitlist-content {
    width: 100%;
    max-width: 420px;
    padding: 0 16px;

    #title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #subtitle {
        margin-bottom: 20px;
        text-align: center;
    }
  }
`;