import { jwtDecode } from 'jwt-decode';
import { useState, createContext, useEffect, Dispatch, SetStateAction } from 'react';
import { FetchAccount, JWToken } from './api_calls/response';
import { clearLocalJwt, fetchAccount, getLocalJwt, setLocalJwt } from './api_calls';
import { JirayaAlert } from './Alert';
import { language } from './Language';

export type StoreContext = {
  isLoggedIn: boolean;
  sideMenu: boolean;
  toggleSideMenu: () => void;
  localLogOut: () => void;
  localLogIn: (jwt: string, account: FetchAccount) => void;
  account: FetchAccount;
  setAccount: (userData: FetchAccount) => void;
  initialLoading: boolean;
  alert: JirayaAlert;
  setAlert: Dispatch<SetStateAction<JirayaAlert>>;
};

export const storeContext = createContext(
  {} as StoreContext
);

const FETCH_INTERVAL = 10_000;

const { fetchAccountError } = language().signedIn;

export const StoreContextProvider = ({ children }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!getLocalJwt());
  const [sideMenu, setSideMenu] = useState<boolean>(false);
  const [account, setAccount] = useState<FetchAccount>({} as FetchAccount);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [jirayaAlert, setJirayaAlert] = useState<JirayaAlert>({} as JirayaAlert);

  const handleFetchAccount = async () => {
    const localJwt = getLocalJwt();
    if (localJwt) {
      if (isTokenExpired(localJwt)) {
        clearLocalJwt();
        setIsLoggedIn(false);
      } else {
        fetchAccount()
          .then(setAccount)
          .catch((e) => {
            if (e.message === 'Logout') {
              clearLocalJwt();
              setIsLoggedIn(false);
              setJirayaAlert({
                severity: 'error',
                message: e,
              });
            } else {
              setJirayaAlert({
                severity: 'error',
                message: fetchAccountError,
              });
            }
          })
          .finally(() => setInitialLoading(false));
      }
    }
  }

  useEffect(() => {
    handleFetchAccount();
    const interval = setInterval(handleFetchAccount, FETCH_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  const value = {
    isLoggedIn,
    sideMenu,
    toggleSideMenu: () => setSideMenu((_sideMenu) => !_sideMenu),
    localLogIn: (jwt: string, account: FetchAccount) => {
      setLocalJwt(jwt);
      setIsLoggedIn(true);
      setAccount(account);
      setInitialLoading(false)
    },
    localLogOut: () => {
      clearLocalJwt();
      setIsLoggedIn(false);
    },
    account,
    setAccount,
    initialLoading,
    alert: jirayaAlert,
    setAlert: setJirayaAlert,
  };

  return (
    <storeContext.Provider value={value}>
      {children}
    </storeContext.Provider>
  );
};

const isTokenExpired = (token: string) => {
  if (!token) {
    return true;
  }

  try {
    const decoded = jwtDecode(token) as JWToken;
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      // Token has expired
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return true;
  }
}
