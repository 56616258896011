import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Deadlines from './Deadlines';
import NewEssay from './NewEssay';
import ExistingEssays from './ExistingEssays';
import { language } from '../../Language';
import { useContext, useEffect, useState } from 'react';
import { queryEssays, querySchools } from '../../api_calls';
import { GetEssayResponse, EssayQueryResponse, QuerySchoolsResponse } from '../../api_calls/response';
import { storeContext } from '../../store';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const strings = language().signedIn.essays;

const Essays = (props: Props) => {
    const { className, initialLoading } = props;

    const { setAlert } = useContext(storeContext);

    const [appliedSchools, setAppliedSchools] = useState<QuerySchoolsResponse[]>([]);
    const [fetchingAppliedSchools, setFetchingAppliedSchools] = useState(false);
    const [existingEssays, setExistingEssays] = useState<EssayQueryResponse[]>([]);
    const [fetchingEssays, setFetchingEssays] = useState(false);

    useEffect(() => {
        setFetchingAppliedSchools(true);
        setFetchingEssays(true);

        querySchools({ applied: true })
            .then((schools) => setAppliedSchools(schools))
            .catch((e) => setAlert({ severity: 'error', message: e }))
            .finally(() => setFetchingAppliedSchools(false));

        queryEssays({})
            .then((essays: EssayQueryResponse[]) => setExistingEssays(essays))
            .catch((e) => setAlert({ severity: 'error', message: e }))
            .finally(() => setFetchingEssays(false));
    }, [setAlert]);

    return (
        <div className={className}>
            <Grid container spacing={1} height='100%'>
                {initialLoading ? <CircularProgress /> : (
                    <>
                        <Grid item xs={12} md={9} height='100%'>
                            <NewEssay
                                strings={strings.newEssay}
                                appliedSchools={appliedSchools}
                                addEssayToList={(essay: GetEssayResponse) => setExistingEssays([...existingEssays, essay])}
                            />
                        </Grid>
                        <Grid container item xs={12} md={3} spacing={1} direction='column' height='100%'>
                            <Grid item xs={6} overflow='scroll'>
                                <Deadlines strings={strings.deadlines} appliedSchools={appliedSchools} fetchingAppliedSchools={fetchingAppliedSchools} />
                            </Grid>
                            <Grid item xs={6} overflow='scroll'>
                                <ExistingEssays strings={strings.existingEssays} existingEssays={existingEssays} fetchingEssays={fetchingEssays} />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default styled(Essays)`
    padding: 5px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 87px);
`;


export const getWordCount = (text: string): number => {
    // Create HTML element
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;

    // Remove HTML tags
    const decodedText = textArea.value.replace(/<[^>]*>/g, ' ');

    // Normalize whitespace: replace multiple whitespace characters with a single space
    const normalizedText = decodedText.replace(/\s+/g, ' ').trim();

    // Split the text by spaces to get an array of words
    const words = normalizedText.split(' ');

    // Filter out any empty strings resulting from the split operation
    return (words.filter(word => word.length > 0).length);
}