import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { States } from '../../lib';
import { Dispatch, SetStateAction } from 'react';
import { SchoolFilterParams } from '../../api_calls/request';
import { SchoolType } from '../../api_calls/response';

interface Props {
    className?: string;
    strings: any;
    schoolFilters: SchoolFilterParams;
    setSchoolFilters: Dispatch<SetStateAction<SchoolFilterParams>>;
    defaultSchoolFilter: SchoolFilterParams;
}

const CollegeFilters = (props: Props) => {
    const { className, strings, schoolFilters, setSchoolFilters, defaultSchoolFilter } = props;

    const handleSchoolTypeChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const eventSchoolType = e.target.id as SchoolType;
        setSchoolFilters((_schoolFilters) => ({
            ..._schoolFilters,
            schoolType: checked ?
                [..._schoolFilters.schoolType, eventSchoolType] :
                _schoolFilters.schoolType.filter((schoolType) => schoolType !== eventSchoolType)
        }));
    };

    const handleAppliedChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setSchoolFilters((_schoolFilters) => ({
            ..._schoolFilters,
            applied: checked
        }));
    };

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h4'>{strings.title}</Typography>
                    <Button
                        onClick={() => setSchoolFilters(defaultSchoolFilter)}
                        variant='outlined'
                        size='small'
                        disabled={schoolFilters.state.length === 0}
                    >
                        {strings.clearFilters}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>{strings.location}</Typography>
                    <Autocomplete
                        multiple
                        options={Object.keys(States)}
                        getOptionLabel={(option) => (States as any)[option]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                            />
                        )}
                        value={schoolFilters.state}
                        onChange={(_, value) => setSchoolFilters({ ...schoolFilters, state: value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>{strings.schoolType}</Typography>
                    <FormGroup row>
                        {Object.keys(SchoolType).map((schoolType) => (
                            <FormControlLabel
                                key={schoolType}
                                control={<Checkbox id={schoolType} checked={schoolFilters.schoolType.includes(schoolType as SchoolType)} onChange={handleSchoolTypeChange} />}
                                label={strings[schoolType]}
                            />
                        ))}
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>{strings.applied}</Typography>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={schoolFilters.applied} onChange={handleAppliedChange} />}
                            label={strings.applied}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </div>
    );
};

export default styled(CollegeFilters)`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
`;
