/* eslint-disable no-extend-native */
declare global {
    interface Date {
        display(): string;
    }
}


// Implement the method on Date.prototype
Date.prototype.display = function (): string {
    const padTo2Digits = (num: number): string => num.toString().padStart(2, '0');

    const month = padTo2Digits(this.getMonth() + 1); // Months are zero-based
    const day = padTo2Digits(this.getDate());
    const year = this.getFullYear().toString().slice(-2); // Get last two digits of the year

    return `${month}/${day}/${year}`;
};

export enum Role {
    User = 'user',
    Assistant = 'assistant',
}
