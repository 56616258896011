import { CircularProgress, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { storeContext } from '../../store';
import { EssayQueryResponse } from '../../api_calls/response';
import { queryEssays } from '../../api_calls';
import { Instructions } from './Deadlines';
import { useNavigate } from 'react-router-dom';

interface Props {
    className?: string;
    strings: any;
}

const EssayEditor = (props: Props) => {
    const { className, strings } = props;

    const { setAlert } = useContext(storeContext);
    const navigate = useNavigate()

    const [existingEssays, setExistingEssays] = useState<EssayQueryResponse[]>([]);
    const [fetchingEssays, setFetchingEssays] = useState(false);

    useEffect(() => {
        setFetchingEssays(true);
        queryEssays({})
            .then((essays: EssayQueryResponse[]) => setExistingEssays(essays))
            .catch((e) => setAlert({ severity: 'error', message: e }))
            .finally(() => setFetchingEssays(false));
    }, [setAlert]);

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h4'>{strings.title}</Typography>
                </Grid>
                <Grid container item xs={12}>
                    {fetchingEssays ? <CircularProgress size={20} /> :
                        existingEssays.length ?
                            existingEssays.map((existingEssay) => (
                                <Grid item xs={12} key={existingEssay.id} className='essay-item' onClick={() => navigate(`/essays/${existingEssay.id}`)}>
                                    <Typography variant='h6'>{existingEssay.title}</Typography>
                                    <Typography variant='h6'>{new Date(existingEssay.createdAt).display()}</Typography>
                                </Grid>
                            )) :
                            <Instructions instructions={strings.instructions} />
                    }
                </Grid>
            </Grid>
        </div>
    );
};

export default styled(EssayEditor)`
    .essay-item {
        border-bottom: 1px solid black;
        padding: 5px;
        margin: 5px;
        display: flex;
        justify-content: space-between;

        &:hover {
            cursor: pointer;
            background-color: #e6e6e6;
            border-radius: 5px 5px 0 0;
        }
    }
`;
