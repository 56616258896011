import { Role } from "../utils";

export type JoinWaitlistResponse = void;
export enum Gender {
    'Male' = 'Male',
    'Female' = 'Female',
    'Other' = 'Other',
};
export enum SchoolType {
    'CommunityCollege' = 'CommunityCollege',
    'University' = 'University',
}
export interface FetchAccount {
    email: string,
    firstName: string,
    lastName: string,
    highSchool: string | null,
    gradYear: number | null,
    gender: Gender | null,
    country: string | null,
    parentalIncome: number | null,
    classRank: number | null,
    gpa: number | null,
    weightedGpa: number | null,
    sat: number | null,
    act: number | null,
}
export interface SignUpResponse {
    token: string;
    student: FetchAccount;
}
export type SignInResponse = SignUpResponse;

export interface QuerySchoolsResponse {
    id: string,
    name: string,
    schoolType: SchoolType,
    state: string,
    admissionRate: number,
    added: boolean,
    deadline: string,
}

export interface JWToken {
    studentId: string;
    exp: number;
}

export interface EssayQueryResponse {
    id: string,
    title: string,
    createdAt: string,
}

export interface GetEssayResponse {
    id: string,
    title: string,
    essay: string,
    applicationId: string,
    schoolId: string,
    createdAt: string,
}

export type SendChatResponse = string;

export interface ConversationHistory {
    role: Role,
    content: string,
    created_at: string,
}

export interface GetConversationHistoryResponse {
    conversationId: string,
    messages: ConversationHistory[],
}