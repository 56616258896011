import { useState, ChangeEvent, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Grid, TextField, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { signUp } from '../../../api_calls';
import { FetchAccount } from '../../../api_calls/response';
import { SignUpFormData } from '../../../api_calls/request';
import { storeContext } from '../../../store';

interface Props {
    className?: string;
    strings: any;
    isSmallScreen: boolean;
    localLogIn: (jwt: string, account: FetchAccount) => void;
}

const inputs = [
    ['firstName', 'lastName'],
    ['email'],
    ['password'],
    ['graduationYear'],
];

const SignUpRightSide = (props: Props) => {
    const { className, strings, isSmallScreen, localLogIn } = props;
    const { setAlert } = useContext(storeContext);

    const [formData, setFormData] = useState<SignUpFormData>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        graduationYear: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData((_formData) => ({ ..._formData, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        signUp(formData)
            .then(({ token, student }) => localLogIn(token, student))
            .catch((error) => setAlert({ severity: 'error', message: error.message }))
            .finally(() => setLoading(false));
    };

    return (
        <Grid className={className} item xs={12} md={8}>
            <div id='right-side'>
                <div>
                    <Typography variant='h4'>{strings.title}</Typography>
                </div>
                <div id='subtitle'>
                    <Typography variant='body2' color='#637381'>{strings.subtitle}</Typography>
                    <Link to='/sign-in'>{strings.alreadyHaveAccount}</Link>
                </div>
                {inputs.map((inputRow, index) => (
                    <Box
                        display='flex'
                        flexDirection={isSmallScreen ? 'column' : 'row'}
                        gap={2}
                        marginBottom={2}
                        key={index}
                    >
                        {inputRow.map((input) => (
                            <TextField
                                key={input}
                                label={(strings as any)[input]}
                                variant='outlined'
                                fullWidth
                                name={input}
                                value={(formData as any)[input]}
                                onChange={handleChange}
                                type={input === 'password' ? 'password' : 'text'}
                            />
                        ))}
                    </Box>
                ))}
                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={handleSubmit}
                    sx={{ marginTop: 2 }}
                    disabled={loading}
                >
                    {strings.signUpButton}
                </Button>
            </div>
        </Grid>
    );
};

export default styled(SignUpRightSide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px;

  #right-side {
    width: 100%;
    max-width: 420px;
    padding: 0 16px;

    #title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #subtitle {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        & > * {
            padding-right: 5px;
        }
    }
  
  }
`;