import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
    className?: string;
    strings: any;
}

const Planner = (props: Props) => {
    const { className, strings } = props;

    return (
        <div className={className}>
            <Grid item xs={12}>
                <Typography variant='h4'>{strings.title}</Typography>
                <Typography variant='body1'>{strings.instructions}</Typography>
            </Grid>
        </div>
    );
};

export default styled(Planner)`

`;
