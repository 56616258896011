import React, { Dispatch, SetStateAction } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import { Slide } from '@mui/material';

export interface JirayaAlert {
    message: string;
    severity: AlertColor;
}

interface JirayaAlertsProps {
    alert: JirayaAlert;
    setAlert: Dispatch<SetStateAction<JirayaAlert>>;
}

const JirayaAlerts = ({ alert, setAlert }: JirayaAlertsProps) => {
    const removeAlert = () => setAlert(({} as JirayaAlert));

    return (
        <div>
            {alert.message &&
                <Snackbar
                    key={alert.message}
                    open
                    autoHideDuration={3000}
                    onClose={() => removeAlert()}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    TransitionComponent={Slide}
                >
                    <Alert
                        onClose={() => removeAlert()}
                        severity={alert.severity}
                        variant='filled'
                    >
                        {alert.message}
                    </Alert>
                </Snackbar>}
        </div>
    );
};

export default JirayaAlerts;
