import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useState } from 'react';
import {
    BtnBold, BtnBulletList, BtnItalic, BtnLink, BtnNumberedList, BtnRedo, BtnStrikeThrough,
    BtnUnderline, BtnUndo, Editor, EditorProvider, Toolbar
} from 'react-simple-wysiwyg';
import WordCount from './WordCount';
import { GetEssayResponse, QuerySchoolsResponse } from '../../../api_calls/response';
import { AddNewEssayData } from '../../../api_calls/request';
import { addEssay } from '../../../api_calls';
import { storeContext } from '../../../store';
import { getWordCount } from '..';
import { useNavigate } from 'react-router-dom';

interface Props {
    className?: string;
    strings: any;
    appliedSchools: QuerySchoolsResponse[];
    addEssayToList: (essay: GetEssayResponse) => void;
}

const NewEssay = (props: Props) => {
    const { className, strings, appliedSchools, addEssayToList } = props;

    const navigate = useNavigate();

    const { setAlert } = useContext(storeContext);

    const [wordCount, setWordCount] = useState(0);
    const [essayInfo, setEssayInfo] = useState<AddNewEssayData>({ title: '', schoolId: '', data: '' });


    const handleSaveEssay = () => addEssay(essayInfo)
        .then((essay) => {
            navigate(`/essays/${essay.id}`);
            addEssayToList(essay);
            setAlert({ severity: 'success', message: strings.saveSuccess });
        })
        .catch(() => setAlert({ severity: 'error', message: strings.saveError }));

    const handleEssayTypeChange = (e: any) => setEssayInfo((_essayInfo) => {
        let data = e.target.value;

        if (data === '<br>') {
            data = '';
        }

        setWordCount(getWordCount(data));

        return { ..._essayInfo, data };
    });

    return (
        <div className={className}>
            <Grid container marginBottom='10px' alignItems='center' justifyContent='center' spacing={3}>
                <Grid item xs={6} sm={5} alignItems='center'>
                    <TextField
                        value={essayInfo.title}
                        onChange={(e) => setEssayInfo((_essayInfo) => ({ ..._essayInfo, title: e.target.value }))}
                        size='small'
                        fullWidth
                        placeholder={strings.title}
                        label={strings.title}
                        variant='standard' />
                </Grid>
                <Grid item xs={6} sm={5} display='flex'>
                    <FormControl fullWidth>
                        <InputLabel>{strings.schoolName}</InputLabel>
                        <Select
                            value={essayInfo.schoolId}
                            onChange={(e) => setEssayInfo((_essayInfo) => ({ ..._essayInfo, schoolId: e.target.value as string }))}
                            variant='standard'
                        >
                            {appliedSchools.map((school, idx) => <MenuItem key={idx} value={school.id}>{school.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} display='flex' justifyContent='flex-end'>
                    <Button
                        variant='contained'
                        onClick={handleSaveEssay}
                        disabled={essayInfo.title === '' || essayInfo.schoolId === ''}
                    >
                        {strings.save}
                    </Button>
                </Grid>
            </Grid>
            <EditorProvider>
                <Editor
                    placeholder={strings.placeHolder}
                    value={essayInfo.data}
                    onChange={handleEssayTypeChange}
                    containerProps={{ style: { height: '93%', background: 'white' } }}
                >
                    <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnStrikeThrough />
                        <BtnLink />
                        <WordCount wordCount={wordCount} strings={strings} />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
};

export default styled(NewEssay)`
    border-radius: 5px;
    padding: 10px;
    height: 100%;

    .rsw-toolbar {
        display: flex;
        justify-content: center;
        size: 30px;
    }

    .rsw-toolbar .rsw-btn {
        font-size: 16px;
        padding: 10px;
        width: 40px; 
        height: 40px;
    }
`;