import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { QuerySchoolsResponse } from '../../../api_calls/response';

interface Props {
    className?: string;
    schoolInfo: QuerySchoolsResponse;
}

const DeadlineCard = (props: Props) => {
    const { className, schoolInfo: { name, deadline, admissionRate } } = props;

    return (
        <div className={className}>
            <Typography variant='h5'>{name}</Typography>
            <Typography variant='subtitle1'>{(100 * admissionRate || 0).toFixed(2)}%</Typography>
            <Typography variant='h6'>{new Date(deadline).toDateString()}</Typography>
        </div>
    );
};

export default styled(DeadlineCard)`
    padding: 10px;
    border-radius: 5px;
    background-color: white;
`;
