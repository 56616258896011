import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { language } from '../../Language';
import Profile from './Profile';
import CollegeFilters from './CollegeFilters';
import CollegeList from './CollegeList';
import { useContext, useEffect, useState } from 'react';
import { QuerySchoolsResponse } from '../../api_calls/response';
import { querySchools } from '../../api_calls';
import { SchoolFilterParams } from '../../api_calls/request';
import { storeContext } from '../../store';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const strings = language().signedIn.colleges;

const defaultSchoolFilter: SchoolFilterParams = ({ name: '', state: [], schoolType: [], applied: false });

const Colleges = (props: Props) => {
    const { className, initialLoading } = props;

    const { setAlert } = useContext(storeContext);

    const [schools, setSchools] = useState<QuerySchoolsResponse[]>([]);
    const [schoolFilters, setSchoolFilters] = useState<SchoolFilterParams>(defaultSchoolFilter);
    const [fetchingSchools, setFetchingSchools] = useState(false);

    useEffect(() => {
        setFetchingSchools(true);
        querySchools(schoolFilters)
            .then(setSchools)
            .catch((e) => setAlert({ severity: 'error', message: e }))
            .finally(() => setFetchingSchools(false));
    }, [schoolFilters, setAlert]);

    return (
        <div className={className}>
            <Grid container spacing={1}>
                {initialLoading ? <CircularProgress /> : (
                    <>
                        <Grid container item xs={12} md={4} alignContent='start' spacing={2}>
                            <Grid item xs={12}>
                                <Profile strings={strings.profile} />
                            </Grid>
                            <Grid item xs={12}>
                                <CollegeFilters
                                    strings={strings.collegeFilters}
                                    schoolFilters={schoolFilters}
                                    setSchoolFilters={setSchoolFilters}
                                    defaultSchoolFilter={defaultSchoolFilter}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={8}>
                            <Grid item xs={12}>
                                <CollegeList
                                    strings={strings.collegeList}
                                    schools={schools}
                                    setSchools={setSchools}
                                    schoolFilters={schoolFilters}
                                    setSchoolFilters={setSchoolFilters}
                                    fetchingSchools={fetchingSchools}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default styled(Colleges)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
