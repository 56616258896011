import { CircularProgress, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeadlineCard from './DeadlineCard';
import { QuerySchoolsResponse } from '../../../api_calls/response';

interface Props {
    className?: string;
    strings: any;
    appliedSchools: QuerySchoolsResponse[];
    fetchingAppliedSchools: boolean;
}

const Deadlines = (props: Props) => {
    const { className, strings, appliedSchools, fetchingAppliedSchools } = props;

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item>
                    <Typography variant='h4'>{strings.title}</Typography>
                </Grid>
                {fetchingAppliedSchools ? <Grid item xs={12}><CircularProgress size={15} /></Grid> :
                    appliedSchools.map((appliedSchool, index) => (
                        <Grid key={index} item xs={12}>
                            <DeadlineCard schoolInfo={appliedSchool} />
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    );
};

export default styled(Deadlines)`
    background-color: #e1e1e1;
    border-radius: 5px;
    padding: 10px;
`;
